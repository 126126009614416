import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const TableHeaderCell = ({ blok, backgroundColor }) => {
  return (
    <Box
      component="th"
      className={`${blok?.background ? 'th-background' : ''} ${blok?.border_bottom ? `th-border-bottom-${blok?.border_bottom}` : ''}`.trim()}
      {...storyblokEditable(blok)}
      sx={{ width: `${blok?.column_width}%` }}
    >
      <Stack
        spacing={3}
        alignItems={blok?.align_center ? 'center' : 'flex-start'}
        justifyContent="flex-start"
        sx={{ height: '100%' }}
      >
        <Typography level="h4">{blok?.text}</Typography>

        {blok?.cta?.map((currentCta) => (
          <StoryblokServerComponent
            key={currentCta?._uid}
            blok={currentCta}
            backgroundColor={backgroundColor}
            size="sm"
          />
        ))}
      </Stack>
    </Box>
  )
}

export default TableHeaderCell
