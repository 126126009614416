import { StoryblokServerComponent } from '@storyblok/react/rsc'

const Trigger = ({ blok, rels, triggerComponent }) => {
  return blok?.trigger?.[0] ? (
    <StoryblokServerComponent blok={blok?.trigger?.[0]} rels={rels} trigger={triggerComponent} />
  ) : (
    triggerComponent
  )
}

export default Trigger
