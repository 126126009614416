import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import getResolvedStoryblokStory from '@/lib/get-resolved-storyblok-story'

const GlobalPlanCard = ({ blok, rels, backgroundColor }) => {
  const content = {
    ...getResolvedStoryblokStory({ item: blok?.plan_card, rels }),
    background: blok?.background || blok?.plan_card?.content?.background,
  }

  return <StoryblokServerComponent {...storyblokEditable(blok)} blok={content} backgroundColor={backgroundColor} />
}

export default GlobalPlanCard
