/**
 * @param {object} blok - object representing the Link type field from Storyblok
 * @return {string} - string with the href value of the link
 */
const determineStoryblokLink = ({ blok }) => {
  let href = '/'
  if (blok?.link?.linktype === 'story') {
    href = blok?.link?.cached_url === 'home' ? '/' : `/${blok?.link?.cached_url}`
  } else {
    href = blok?.link?.cached_url
  }

  if (blok?.link?.anchor) {
    href = `${href}#${blok?.link?.anchor}`
  }

  return href
}

export default determineStoryblokLink
