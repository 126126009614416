import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'

const TableCell = ({ blok, firstCell, backgroundColor }) => {
  const level = firstCell ? 'title-md' : 'body-md'
  const mainContentComponent = blok?.main_content?.[0]?.component

  return (
    <Box
      {...storyblokEditable(blok)}
      component="td"
      sx={{ verticalAlign: mainContentComponent === 'Checkbox' ? 'middle' : 'top' }}
    >
      <Stack spacing={3} sx={{ alignItems: mainContentComponent === 'Checkbox' ? 'center' : 'flex-start' }}>
        {blok?.main_content?.map((currentMainContent) => (
          <StoryblokServerComponent
            key={currentMainContent?._uid}
            blok={currentMainContent}
            backgroundColor={backgroundColor}
            options={{ NODE_PARAGRAPH: { level } }}
            level={level}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default TableCell
