'use client'

import { useState, useRef, useEffect } from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Button from '@audioeye-marketing/ui/src/components/Button'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import ChevronDown from '@audioeye/ui-icons/Line/Arrows/ChevronDown'

const MegaMenu = ({ blok, rels, navItemNumber }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const toggleButtonRef = useRef(null) // Reference to the toggle button
  const [menuLeftPosition, setMenuLeftPosition] = useState(0)
  const menuPosition =
    navItemNumber === 1
      ? { xs: '-100px', xl: '-100px' }
      : navItemNumber === 2
        ? { xs: '-100px', xl: '-100px' }
        : navItemNumber >= 3
          ? { xs: '-500px', xl: '-175px' }
          : { xs: '-100px', xl: '-100px' }

  useEffect(() => {
    if (menuOpen) {
      // Focus on the first focusable element when the menu opens
      const focusableElements = menuRef.current?.querySelectorAll(
        'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      focusableElements?.[0]?.focus()
    }
  }, [menuOpen])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!menuOpen) return

      const focusableElements = menuRef.current?.querySelectorAll(
        'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
      const firstElement = focusableElements?.[0]
      const lastElement = focusableElements?.[focusableElements.length - 1]

      if (event.key === 'Tab') {
        if (event.shiftKey) {
          // If Shift + Tab, and on the first element, move focus to the last
          if (document.activeElement === firstElement) {
            event.preventDefault()
            lastElement?.focus()
          }
        } else if (document.activeElement === lastElement) {
          event.preventDefault()
          firstElement?.focus()
        }
      } else if (event.key === 'Escape') {
        setMenuOpen(false)
        toggleButtonRef.current?.focus() // Focus the toggle button when menu closes
      }
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }

    if (menuOpen) {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuOpen])

  useEffect(() => {
    const updateMenuPosition = () => {
      if (!toggleButtonRef.current) return 0
      const triggerRect = toggleButtonRef.current.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const remainingSpace = viewportWidth - 1160
      const menuCenterOffset = remainingSpace > 0 ? remainingSpace / 2 : 0
      setMenuLeftPosition(triggerRect.left - menuCenterOffset)
    }

    updateMenuPosition()

    window.addEventListener('resize', updateMenuPosition)
    return () => {
      window.removeEventListener('resize', updateMenuPosition)
    }
  }, [])

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      {/* Button to toggle the menu */}
      <Button
        {...storyblokEditable(blok)}
        ref={toggleButtonRef} // Set ref to the toggle button
        onClick={() => setMenuOpen((prev) => !prev)}
        endDecorator={<ChevronDown />}
        aria-expanded={menuOpen}
        aria-controls="megamenu"
        color="neutral"
        variant="plain"
        size="md"
      >
        {blok?.button_text}
      </Button>

      {/* Dropdown menu */}
      <Box
        ref={menuRef}
        id={`megamenu-${blok?.button_text}`}
        role="menu"
        sx={{
          display: menuOpen ? 'block' : 'none',
          position: 'absolute',
          top: '57px',
          left: { xs: `-${menuLeftPosition}px`, xl: menuPosition.xl },
          zIndex: 1000,
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          padding: 0,
          width: '1160px',
          backgroundColor: colors.common.white,
          boxShadow:
            'var(--joy-shadowRing, 0 0 #000),0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)),0px 6px 12px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
        }}
      >
        <Grid container>
          {blok?.rows?.map((currentRow, index) => (
            <StoryblokServerComponent
              blok={currentRow}
              key={currentRow?._uid}
              rels={rels}
              horizontalForm
              underline={blok?.rows?.length >= 2 && index < blok?.rows?.length - 1}
            />
          ))}
          <Grid xs={3} sx={{ borderTop: '1px solid var(--joy-palette-divider)' }}>
            <Box
              pt={2}
              pb={2}
              pl={4}
              pr={4}
              sx={{
                borderRight: '1px solid var(--joy-palette-divider)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
              }}
            >
              {blok?.cta?.map((currentCta) => (
                <StoryblokServerComponent blok={currentCta} key={currentCta?._uid} rels={rels} size="sm" />
              ))}
            </Box>
          </Grid>
          <Grid xs={9} sx={{ borderTop: '1px solid var(--joy-palette-divider)' }}>
            <Box
              pt={2}
              pb={2}
              pl={5.5}
              pr={5.5}
              sx={{
                backgroundColor: colors.neutral[50],
                borderBottomRightRadius: '8px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {blok?.featured_item?.map((featuredItem) => (
                <StoryblokServerComponent blok={featuredItem} key={featuredItem?._uid} horizontalForm />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MegaMenu
