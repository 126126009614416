import colors from './colors'

const materialTheme = {
  components: {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
            backgroundColor: colors.primary[500],
          },
          '.MuiDialogActions-root .MuiButtonBase-root': {
            color: colors.primary[500],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiXDatePicker .MuiInputBase-root': {
            height: '44px',
          },
          '&.MuiXDatePicker .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.neutral[300]}`,
            borderRadius: '6px',
          },
          '&.MuiXDatePicker .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.primary[500]} !important`,
          },
          '.MuiFormHelperText-root': {
            // * We show our own validation helper text
            display: 'none',
          },
        },
      },
    },
  },
}

export default materialTheme
