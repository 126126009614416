import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const DatePicker = ({ ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialDatePicker className="MuiXDatePicker" {...rest} />
    </LocalizationProvider>
  )
}

export default DatePicker
