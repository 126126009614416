'use client'

import React, { createContext } from 'react'
import { defaultLanguage } from '@/lib/languages'

const App = createContext()

const AppProvider = ({ children, language, country }) => {
  const currentLanguage = language || defaultLanguage
  const currentCountry = country || 'US'
  const isEUVisitor =
    currentCountry === 'DE' ||
    currentCountry === 'IT' ||
    currentCountry === 'ES' ||
    currentCountry === 'FR' ||
    currentCountry === 'DK' ||
    currentCountry === 'FI' ||
    currentCountry === 'IS' ||
    currentCountry === 'NO' ||
    currentCountry === 'NL' ||
    currentCountry === 'GB' ||
    currentCountry === 'UK' ||
    currentCountry === 'SE' ||
    currentCountry === 'IE'

  return <App.Provider value={{ currentLanguage, currentCountry, isEUVisitor }}>{children}</App.Provider>
}

const useAppContext = () => {
  const context = React.useContext(App)
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context
}

export { AppProvider, useAppContext }
