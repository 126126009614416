import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import AccordionGroup from '@audioeye-marketing/ui/src/components/AccordionGroup'

const Accordion = ({ blok, backgroundColor }) => {
  return (
    <AccordionGroup {...storyblokEditable(blok)} size="lg" sx={{ maxWidth: '800px' }}>
      {blok?.items?.map((item) => (
        <StoryblokServerComponent key={item?._uid} blok={item} backgroundColor={backgroundColor} />
      ))}
    </AccordionGroup>
  )
}

export default Accordion
