import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Card from '@audioeye-marketing/ui/src/components/Card'
import translateSectionStyles from '@/lib/translate-section-styles'

const QuoteContent = ({ blok, rels, imagePriority }) => {
  const sectionStyles = translateSectionStyles(blok.section_styles?.[0])
  const Wrapper = blok?.card ? Card : Box
  const wrapperProps = blok?.card
    ? {
        sx: {
          paddingLeft: { xs: 2.5, md: 5 },
          paddingRight: { xs: 2.5, md: blok?.image?.length > 0 ? 0 : 5 },
          paddingTop: { xs: 2.5, md: 5 },
          paddingBottom: { xs: 2.5, md: 5 },
        },
        noBorderRadius: true,
      }
    : {}

  return (
    <Section
      {...storyblokEditable(blok)}
      {...sectionStyles}
      inverseOnWhite={Boolean(blok?.inverse_on_white && blok?.card)}
      inverseOnNeutral={Boolean(blok?.inverse_on_neutral && blok?.card)}
      collapseRightPaddingMediumUp={!blok?.card && blok?.image?.length > 0}
      id={blok?.anchor?.[0]?.anchor_id}
    >
      {/* className necessary for color determiner in Section.jsx */}
      <Container constraint="lg" className="SectionQuote">
        <Wrapper variant="soft" {...wrapperProps}>
          <Container constraint="lg">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={5}>
              <Stack spacing={2} justifyContent="space-between" sx={{ flex: 5 }}>
                {blok?.quote?.map((currentQuote) => (
                  <StoryblokServerComponent
                    key={blok?._uid}
                    blok={currentQuote}
                    rels={rels}
                    imagePriority={imagePriority}
                    backgroundColor={sectionStyles?.backgroundColor}
                  />
                ))}
                {blok?.cta?.map((currentCta) => (
                  <StoryblokServerComponent
                    key={blok?._uid}
                    blok={currentCta}
                    rels={rels}
                    backgroundColor={sectionStyles?.backgroundColor}
                  />
                ))}
              </Stack>

              {blok?.image?.map((currentImage) => (
                <Box key={currentImage?._uid} sx={{ flex: 3 }}>
                  <StoryblokServerComponent blok={currentImage} rels={rels} imagePriority={imagePriority} />
                </Box>
              ))}
            </Stack>
          </Container>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default QuoteContent
