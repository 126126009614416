import Box from '../Box'
import determinePadding from '../../lib/determine-padding'
import determineColors from '../../lib/determine-colors'
import colors from '../../theme/colors'

const Section = ({
  children,
  padding,
  collapseTopPadding,
  collapseBottomPadding,
  collapseLeftPadding,
  collapseRightPadding,
  collapseRightPaddingMediumUp,
  backgroundColor,
  inverseOnWhite,
  inverseOnNeutral,
  ...rest
}) => {
  const calculatedPadding = determinePadding({
    padding,
    collapseTopPadding,
    collapseBottomPadding,
    collapseLeftPadding,
    collapseRightPadding,
    collapseRightPaddingMediumUp,
  })

  const calculatedColors = determineColors({
    backgroundColor,
    inverseOnWhite,
    inverseOnNeutral,
  })

  return (
    <Box
      component="section"
      sx={{
        ...rest.sx,
        position: 'relative', // Necessary for things like the textured image in Split Content
        backgroundColor: calculatedColors?.backgroundColor || colors.common.white,
        paddingTop: calculatedPadding.paddingTop,
        paddingBottom: calculatedPadding.paddingBottom,
        paddingLeft: calculatedPadding.paddingLeft,
        paddingRight: calculatedPadding.paddingRight,
        '--joy-palette-background-surface': `${backgroundColor} !important`,
        // * Typography - primary
        '.MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-title-lg, .MuiTypography-title-md, .MuiTypography-title-sm':
          {
            color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-primary)' : 'var(--joy-palette-textOnDark-primary)'} !important`,
          },
        // * Typography - secondary
        '.MuiTypography-body-lg, .MuiTypography-body-md, .MuiTypography-code-md': {
          color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-textOnDark-secondary)'} !important`,
        },
        // * Typography - tertiary
        '.MuiTypography-body-sm, .MuiTypography-body-xs, .MuiTypography-code-sm, .MuiTypography-code-xs': {
          color: `${calculatedColors?.Typography?.text === 'light' ? 'var(--joy-palette-text-tertiary)' : 'var(--joy-palette-textOnDark-tertiary)'} !important`,
        },
        // * Typography - eyebrow
        '.MuiTypography-eyebrow': {
          color: `${calculatedColors?.Typography?.eyebrow} !important`,
        },
        // * List Item
        '.MuiListItemDecorator-root': {
          color: `${calculatedColors?.ListItem?.listItemDecorator} !important`,
        },
        // * Quote
        '.SectionQuote .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.display} !important`,
        },
        '.SectionQuote .MuiCard-root .MuiTypography-display-md': {
          color: `${calculatedColors?.Quote?.Card?.display} !important`,
        },
        '.SectionQuote .MuiTypography-display-sm': {
          color: `${calculatedColors?.Quote?.display} !important`,
        },
        '.SectionQuote .MuiCard-root .MuiTypography-display-sm': {
          color: `${calculatedColors?.Quote?.Card?.display} !important`,
        },
        // * SVG
        '.blok-svg svg': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-svg path': {
          fill: calculatedColors?.Svg?.image,
        },
        '.blok-icon svg': {
          stroke: calculatedColors?.Svg?.icon,
        },
        '.blok-icon path': {
          stroke: calculatedColors?.Svg?.icon,
        },
        // * Card
        '.MuiCard-root': {
          backgroundColor: `${calculatedColors?.Card?.backgroundColor}`,
          border: `1px solid ${calculatedColors?.Card?.border}`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger': {
          '--_Input-focusedHighlight': `${calculatedColors?.Card?.Input?.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiInput-colorDanger .MuiInput-input': {
          color: `${calculatedColors?.Card?.Input?.invalid} !important`,
        },
        '.MuiCard-root .Mui-error .MuiFormHelperText-root': {
          color: `${calculatedColors?.Card?.Input?.invalid} !important`,
        },
        '.MuiCard-root .blok-icon svg': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        '.MuiCard-root .blok-icon path': {
          stroke: calculatedColors?.Card?.Svg?.icon,
        },
        // * Plan Card - Light
        '.MuiCard-root.blok-plan-card-light .MuiListItemDecorator-root': {
          color: `${calculatedColors?.PlanCard?.light?.ListItem?.listItemDecorator} !important`,
        },
        '.MuiCard-root.blok-plan-card-light .MuiTypography-h1, .MuiCard-root.blok-plan-card-light .MuiTypography-h2, .MuiCard-root.blok-plan-card-light .MuiTypography-h3, .MuiCard-root.blok-plan-card-light .MuiTypography-h4, .MuiCard-root.blok-plan-card-light .MuiTypography-title-lg, .MuiCard-root.blok-plan-card-light .MuiTypography-title-md, .MuiCard-root.blok-plan-card-light .MuiTypography-title-sm':
          {
            color: `${calculatedColors?.PlanCard?.light?.Typography?.text === 'light' ? 'var(--joy-palette-text-primary)' : 'var(--joy-palette-textOnDark-primary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-light .MuiTypography-body-lg, .MuiCard-root.blok-plan-card-light .MuiTypography-body-md, .MuiCard-root.blok-plan-card-light .MuiTypography-code-md':
          {
            color: `${calculatedColors?.PlanCard?.light?.Typography?.text === 'light' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-textOnDark-secondary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-light .MuiTypography-body-sm, .MuiCard-root.blok-plan-card-light .MuiTypography-body-xs, .MuiCard-root.blok-plan-card-light .MuiTypography-code-sm, .MuiCard-root.blok-plan-card-light .MuiTypography-code-xs':
          {
            color: `${calculatedColors?.PlanCard?.light?.Typography?.text === 'light' ? 'var(--joy-palette-text-tertiary)' : 'var(--joy-palette-textOnDark-tertiary)'} !important`,
          },
        // * Plan Card - Medium
        '.MuiCard-root.blok-plan-card-medium .MuiListItemDecorator-root': {
          color: `${calculatedColors?.PlanCard?.medium?.ListItem?.listItemDecorator} !important`,
        },
        '.MuiCard-root.blok-plan-card-medium .MuiTypography-h1, .MuiCard-root.blok-plan-card-medium .MuiTypography-h2, .MuiCard-root.blok-plan-card-medium .MuiTypography-h3, .MuiCard-root.blok-plan-card-medium .MuiTypography-h4, .MuiCard-root.blok-plan-card-medium .MuiTypography-title-lg, .MuiCard-root.blok-plan-card-medium .MuiTypography-title-md, .MuiCard-root.blok-plan-card-medium .MuiTypography-title-sm':
          {
            color: `${calculatedColors?.PlanCard?.medium?.Typography?.text === 'light' ? 'var(--joy-palette-text-primary)' : 'var(--joy-palette-textOnDark-primary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-medium .MuiTypography-body-lg, .MuiCard-root.blok-plan-card-medium .MuiTypography-body-md, .MuiCard-root.blok-plan-card-medium .MuiTypography-code-md':
          {
            color: `${calculatedColors?.PlanCard?.medium?.Typography?.text === 'light' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-textOnDark-secondary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-medium .MuiTypography-body-sm, .MuiCard-root.blok-plan-card-medium .MuiTypography-body-xs, .MuiCard-root.blok-plan-card-medium .MuiTypography-code-sm, .MuiCard-root.blok-plan-card-medium .MuiTypography-code-xs':
          {
            color: `${calculatedColors?.PlanCard?.medium?.Typography?.text === 'light' ? 'var(--joy-palette-text-tertiary)' : 'var(--joy-palette-textOnDark-tertiary)'} !important`,
          },
        // * Plan Card - Dark
        '.MuiCard-root.blok-plan-card-dark .MuiListItemDecorator-root': {
          color: `${calculatedColors?.PlanCard?.dark?.ListItem?.listItemDecorator} !important`,
        },
        '.MuiCard-root.blok-plan-card-dark .MuiTypography-h1, .MuiCard-root.blok-plan-card-dark .MuiTypography-h2, .MuiCard-root.blok-plan-card-dark .MuiTypography-h3, .MuiCard-root.blok-plan-card-dark .MuiTypography-h4, .MuiCard-root.blok-plan-card-dark .MuiTypography-title-lg, .MuiCard-root.blok-plan-card-dark .MuiTypography-title-md, .MuiCard-root.blok-plan-card-dark .MuiTypography-title-sm':
          {
            color: `${calculatedColors?.PlanCard?.dark?.Typography?.text === 'light' ? 'var(--joy-palette-text-primary)' : 'var(--joy-palette-textOnDark-primary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-dark .MuiTypography-body-lg, .MuiCard-root.blok-plan-card-dark .MuiTypography-body-md, .MuiCard-root.blok-plan-card-dark .MuiTypography-code-md':
          {
            color: `${calculatedColors?.PlanCard?.dark?.Typography?.text === 'light' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-textOnDark-secondary)'} !important`,
          },
        '.MuiCard-root.blok-plan-card-dark .MuiTypography-body-sm, .MuiCard-root.blok-plan-card-dark .MuiTypography-body-xs, .MuiCard-root.blok-plan-card-dark .MuiTypography-code-sm, .MuiCard-root.blok-plan-card-dark .MuiTypography-code-xs':
          {
            color: `${calculatedColors?.PlanCard?.dark?.Typography?.text === 'light' ? 'var(--joy-palette-text-tertiary)' : 'var(--joy-palette-textOnDark-tertiary)'} !important`,
          },
        //* Job Openings Card
        '.job-openings-card': {
          borderLeft: `4px solid ${calculatedColors?.JobOpeningsCard?.borderColor}`,
        },
        // * Card With Form
        '.MuiCard-root.CardWithForm': {
          backgroundColor: `${calculatedColors?.CardWithForm?.backgroundColor} !important`,
        },
        '.MuiCard-root.CardWithForm .MuiListItemDecorator-root': {
          color: `${calculatedColors?.PlanCard?.light?.ListItem?.listItemDecorator} !important`,
        },
        '.MuiCard-root.CardWithForm .MuiTypography-h1, .MuiCard-root.CardWithForm .MuiTypography-h2, .MuiCard-root.CardWithForm .MuiTypography-h3, .MuiCard-root.CardWithForm .MuiTypography-h4, .MuiCard-root.CardWithForm .MuiTypography-title-lg, .MuiCard-root.CardWithForm .MuiTypography-title-md, .MuiCard-root.CardWithForm .MuiTypography-title-sm':
          {
            color: 'var(--joy-palette-text-primary) !important',
          },
        '.MuiCard-root.CardWithForm .MuiTypography-body-lg, .MuiCard-root.CardWithForm .MuiTypography-body-md, .MuiCard-root.CardWithForm .MuiTypography-code-md':
          {
            color: 'var(--joy-palette-text-secondary) !important',
          },
        '.MuiCard-root.CardWithForm .MuiTypography-body-sm, .MuiCard-root.CardWithForm .MuiTypography-body-xs, .MuiCard-root.CardWithForm .MuiTypography-code-sm, .MuiCard-root.CardWithForm .MuiTypography-code-xs':
          {
            color: 'var(--joy-palette-text-tertiary) !important',
          },
        // * Footer
        '&:is(footer) .intro-text': {
          color: `${calculatedColors?.Footer?.introText} !important`,
        },
        '&:is(footer) .MuiTypography-root': {
          color: `${calculatedColors?.Footer?.text}  !important`,
        },
        // Done after so it overrides the above
        '&:is(footer) .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        '&:is(footer) .MuiCard-root .MuiTypography-title-lg': {
          color: `${calculatedColors?.Footer?.listTitle} !important`,
        },
        // * Accordion
        '.MuiAccordion-root .MuiAccordionSummary-button': {
          color: `${calculatedColors?.Accordion?.summaryText} !important`,
        },
        '.MuiAccordion-root .MuiAccordionSummary-indicator svg': {
          color: `${calculatedColors?.Accordion?.icon} !important`,
        },
        '.MuiAccordionSummary-button:not(.Mui-selected, [aria-selected="true"]):hover': {
          backgroundColor: `${calculatedColors?.Accordion?.hoverBg} !important`,
        },
        // * Table
        '.MuiTable-root.blok-table tbody tr > *:first-child': {
          backgroundColor: `${calculatedColors?.Table?.firstColumn?.backgroundColor} !important`,
        },
        '.MuiTable-root.blok-table thead tr th .MuiTypography-root': {
          color: `${calculatedColors?.Table?.header?.text} !important`,
        },
        '.MuiTable-root.blok-table thead .th-border-bottom-light': {
          boxShadow: `inset 0 -4px 0 ${calculatedColors?.Table?.header?.borderLight} !important`,
        },
        '.MuiTable-root.blok-table thead .th-border-bottom-medium': {
          boxShadow: `inset 0 -4px 0 ${calculatedColors?.Table?.header?.borderMedium} !important`,
        },
        '.MuiTable-root.blok-table thead .th-border-bottom-dark': {
          boxShadow: `inset 0 -4px 0 ${calculatedColors?.Table?.header?.borderDark} !important`,
        },
        '.MuiTable-root.blok-table thead .th-background': {
          backgroundColor: `${calculatedColors?.Table?.header?.backgroundColor} !important`,
        },
        '.MuiTable-root.blok-table .blok-icon svg': {
          stroke: calculatedColors?.Table?.Svg?.icon,
        },
        '.MuiTable-root.blok-table .blok-icon path': {
          stroke: calculatedColors?.Table?.Svg?.icon,
        },
        '.MuiTable-root.blok-table td, .MuiTable-root.blok-table th': {
          borderColor: calculatedColors?.Table?.borderColor,
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Section
