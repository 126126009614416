import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import NextLink from 'next/link'
import UIChip from '@audioeye-marketing/ui/src/components/Chip'
import ChevronRight from '@audioeye/ui-icons/Line/Arrows/ChevronRight'
import determineChipColor from '@/lib/determine-chip-color'
import determineChipVariant from '@/lib/determine-chip-variant'

const Chip = ({ blok, backgroundColor, subBackgroundColor, color, size }) => {
  const chipColor = color || determineChipColor({ backgroundColor, subBackgroundColor })
  const variant = determineChipVariant({ variant: blok?.variant })
  const triggerBlok = blok?.trigger?.[0]
  const chipProps = triggerBlok
    ? { component: 'div', slotProps: { action: { component: NextLink } }, endDecorator: <ChevronRight /> }
    : null

  const FinalChip = (
    <UIChip
      {...storyblokEditable(blok)}
      {...chipProps}
      size={size || 'md'}
      variant={variant}
      color={chipColor}
      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
    >
      {blok?.text}
    </UIChip>
  )

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalChip} /> : FinalChip
}

export default Chip
