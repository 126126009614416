import colors from '@audioeye-marketing/ui/src/theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @param {string} subBackgroundColor - string representing the sub background color (surface within a section)
 * @return {string} - string representing the chip color
 */
const determineChipColor = ({ backgroundColor, subBackgroundColor = 'medium' }) => {
  const chipColors = {
    [colors.neutral[50]]: {
      light: 'primary',
      medium: 'primary',
      dark: 'light',
    },
    [colors.common.white]: {
      light: 'primary',
      medium: 'primary',
      dark: 'light',
    },
    [colors.common.black]: {
      light: 'light',
      medium: 'light',
      dark: 'success',
    },
    [colors.primary[900]]: {
      light: 'light',
      medium: 'light',
      dark: 'success',
    },
    [colors.secondary[900]]: {
      light: 'light',
      medium: 'light',
      dark: 'success',
    },
    [colors.tertiary[900]]: {
      light: 'light',
      medium: 'light',
      dark: 'success',
    },
    [colors.info[900]]: {
      light: 'light',
      medium: 'light',
      dark: 'success',
    },
  }

  return chipColors?.[backgroundColor]?.[subBackgroundColor] || chipColors[colors.common.white].medium
}

export default determineChipColor
