import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'

const TableHeaderRow = ({ blok, backgroundColor }) => {
  return (
    <tr {...storyblokEditable(blok)}>
      {blok?.cells?.map((currentCells) => (
        <StoryblokServerComponent key={currentCells?._uid} blok={currentCells} backgroundColor={backgroundColor} />
      ))}
    </tr>
  )
}

export default TableHeaderRow
