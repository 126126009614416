import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import SkipLink from '@audioeye-marketing/ui/src/components/SkipLink'
import Frame from '@/components/storyblok/global/Frame'
import PasswordProtectedPage from '@/components/PasswordProtectedPage'

const Page = async ({ blok, rels, storyId, authenticationCookie }) => {
  const numberOfBeforeContentSections = blok?.frame?.content?.before_content?.length
  const hasBeforeContent = numberOfBeforeContentSections > 0

  if (
    blok?.password &&
    authenticationCookie?.value !== 'true' &&
    process.env.NEXT_PUBLIC_WWW_APP_ENV === 'production'
  ) {
    return <PasswordProtectedPage cmsPassword={blok?.password} storyId={storyId} />
  }

  return (
    <div {...storyblokEditable(blok)}>
      <SkipLink />
      <Frame blok={blok?.frame?.content} rels={rels}>
        {blok?.body?.map((currentSection, index) => (
          <StoryblokServerComponent
            blok={currentSection}
            key={currentSection?._uid}
            rels={rels}
            headingLevel={index === 0 && !hasBeforeContent ? 1 : 2}
            imagePriority={numberOfBeforeContentSections + index === 0 || numberOfBeforeContentSections + index === 1}
          />
        ))}
      </Frame>
    </div>
  )
}

export default Page
