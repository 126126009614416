import React from 'react'
import JoyBox from '@mui/joy/Box'

const Box = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <JoyBox ref={ref} {...rest}>
      {children}
    </JoyBox>
  )
})

Box.displayName = 'Box'

export default Box
