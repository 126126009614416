import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import colors from '@audioeye-marketing/ui/src/theme/colors'

const MegaMenuColumn = ({ blok, rels }) => {
  return (
    <Grid
      {...storyblokEditable(blok)}
      xs={6}
      lg={4}
      sx={{ pl: 4, pr: 4, pt: 3, pb: 3, borderRight: blok?.divider ? '1px solid var(--joy-palette-divider)' : '' }}
    >
      <Stack spacing={4} sx={{ height: '100%' }}>
        {blok?.list_groups?.map((currentListGroup) => {
          return (
            <StoryblokServerComponent
              blok={currentListGroup}
              key={currentListGroup?._uid}
              collapsePadding
              removeListItemTargetBlankIcon
              backgroundColor={colors.common.white}
            />
          )
        })}

        {blok?.cta?.map((currentCta) => {
          return (
            <StoryblokServerComponent
              key={currentCta?._uid}
              blok={currentCta}
              rels={rels}
              color="neutral"
              size="sm"
              arrowDecorator
              backgroundColor={colors.common.white}
            />
          )
        })}
      </Stack>
    </Grid>
  )
}

export default MegaMenuColumn
