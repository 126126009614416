import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const ButtonGroup = ({ blok, rels, backgroundColor, fullWidth, forceFullWidth, size, alignItems, justifyContent }) => {
  return (
    <Stack
      {...storyblokEditable(blok)}
      direction={{ xs: 'column', sm: forceFullWidth ? 'column' : 'row' }}
      spacing={3}
      sx={{ width: fullWidth || forceFullWidth ? '100%' : 'auto' }}
      alignItems={alignItems || 'flex-start'}
      justifyContent={justifyContent}
    >
      {blok?.buttons?.map((currentButton) => {
        return (
          <StoryblokServerComponent
            key={currentButton?._uid}
            blok={currentButton}
            rels={rels}
            backgroundColor={backgroundColor}
            fullWidth={fullWidth}
            forceFullWidth={forceFullWidth}
            size={size}
          />
        )
      })}
    </Stack>
  )
}

export default ButtonGroup
