import parse, { attributesToProps, domToReact } from 'html-react-parser'
import Typography from '../components/Typography'
import Link from '../components/Link'

/**
 * @param {string} htmlString - string that may contain HTML tags
 * @return {object} - React components to render the HTML string
 */
const parseHtmlString = (htmlString, level) => {
  // Check if the input is just a regular string without any HTML tags
  if (!/<[^>]*>/g.test(htmlString)) {
    return <Typography level={level || 'body-lg'}>{htmlString}</Typography>
  }

  return parse(htmlString, {
    replace: ({ attribs, name, children }) => {
      const props = attributesToProps(attribs)

      if (name === 'p') {
        return (
          <Typography level={level || 'body-lg'} {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h1') {
        return (
          <Typography level="h1" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h2') {
        return (
          <Typography level="h2" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h3') {
        return (
          <Typography level="h3" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h4') {
        return (
          <Typography level="h4" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h5') {
        return (
          <Typography level="h5" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'h6') {
        return (
          <Typography level="h6" {...props}>
            {domToReact(children)}
          </Typography>
        )
      }

      if (name === 'a') {
        return <Link {...props}>{domToReact(children)}</Link>
      }
    },
  })
}

export default parseHtmlString
