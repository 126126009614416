import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import translateSectionStyles from '@/lib/translate-section-styles'

const LogoBanner = ({ blok, rels }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const titleAndCtaAlignment = blok?.variant === 'default' ? 'center' : { xs: 'center', md: 'left' }
  const spacing = 4

  const title = blok?.title ? (
    <Typography level="title-lg" textAlign={titleAndCtaAlignment}>
      {blok?.title}
    </Typography>
  ) : null

  const cta =
    blok?.cta?.length > 0 ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: titleAndCtaAlignment,
        }}
      >
        <StoryblokServerComponent blok={blok?.cta[0]} rels={rels} backgroundColor={sectionStyles?.backgroundColor} />
      </Box>
    ) : null

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} textAlign="center" id={blok?.anchor?.[0]?.anchor_id}>
      <Container constraint="lg">
        <Stack spacing={5}>
          {blok?.variant !== 'two-column' && title}
          <Stack
            spacing={{ xs: blok?.variant === 'two-column' ? 3 : 0, md: 8 }}
            direction={{ xs: blok?.variant === 'two-column' ? 'column' : 'row', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
          >
            {blok?.variant === 'two-column' && (
              <Stack
                spacing={3}
                sx={{
                  flex: 3,
                }}
              >
                {title}
                {cta}
              </Stack>
            )}
            <Grid
              container
              columnSpacing={spacing}
              rowSpacing={2}
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md:
                    blok?.variant !== 'left-aligned' && blok?.justify_logos === 'squished'
                      ? 'center'
                      : blok?.justify_logos === 'squished'
                        ? 'flex-start'
                        : 'space-between',
                  lg:
                    blok?.variant !== 'default' && blok?.justify_logos === 'squished'
                      ? 'flex-start'
                      : blok?.justify_logos === 'squished'
                        ? 'center'
                        : 'space-between',
                },
                alignItems: blok?.variant === 'left-aligned' ? { xs: 'flex-start', sm: 'center' } : 'center',
                width: '100%',
                '--Grid-columns': 5,
                flex: 9,
                marginLeft: blok?.variant !== 'left-aligned' ? 'auto' : `-${spacing * 4}px !important`,
                marginRight: blok?.variant !== 'left-aligned' ? 'auto' : `-${spacing * 4}px !important`,
              }}
            >
              {blok?.logos?.map((currentLogo) => {
                return (
                  <Grid
                    key={currentLogo?._uid}
                    sx={{
                      minWidth: { xs: '120px', md: 'auto' },
                    }}
                    xs={1}
                  >
                    <Box sx={{ maxWidth: '144px', margin: blok?.variant !== 'left-aligned' ? 'auto' : 'auto 0' }}>
                      <StoryblokServerComponent blok={currentLogo} rels={rels} />
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Stack>
          {blok?.variant !== 'two-column' && cta}
        </Stack>
      </Container>
    </Section>
  )
}

export default LogoBanner
