import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'

const IconText = ({ blok, level }) => {
  return (
    <Stack {...storyblokEditable(blok)} direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
      {blok?.icon?.map((currentIcon) => (
        <Box key={currentIcon?._uid} sx={{ alignSelf: 'flex-start', position: 'relative', top: '2px' }}>
          <StoryblokServerComponent blok={currentIcon} width={20} height={20} />
        </Box>
      ))}
      <Typography level={level || 'body-md'}>{blok?.text}</Typography>
    </Stack>
  )
}

export default IconText
