'use client'

import { useEffect } from 'react'
import FormLoading from '../FormLoading'
import trackEvent from '../../lib/track-event'
import ReactHookForm from './ReactHookForm'
import HubspotFormContent from './HubspotFormContent'

const HubspotForm = ({
  formId,
  formName,
  language,
  fieldGroups,
  legalConsentOptions,
  submitButtonText = 'Submit',
  onSubmit,
  isLoading,
  isSubmitting,
  ...rest
}) => {
  // If there are legal consent options, then build the structure of those into the fieldGroups so that they can be rendered
  const newFieldGroups = legalConsentOptions?.communicationsCheckboxes
    ? [
        ...fieldGroups,
        ...legalConsentOptions.communicationsCheckboxes.map((currentCheckbox) => {
          return {
            groupType: 'default_group',
            richTextType: 'text',
            fields: [
              {
                name: `LEGAL_CONSENT.subscription_type_${currentCheckbox?.subscriptionTypeId}`,
                label: currentCheckbox?.label.replaceAll(/<[^>]*>/gi, ''),
                required: currentCheckbox?.required,
                hidden: false,
                fieldType: 'single_checkbox',
              },
            ],
          }
        }),
        {
          groupType: 'default_group',
          richTextType: 'text',
          fields: [
            {
              objectTypeId: '0-1',
              name: 'LEGAL_CONSENT.processing',
              label: legalConsentOptions.consentToProcessCheckboxLabel.replaceAll(/<[^>]*>/gi, ''),
              required: true,
              hidden: false,
              fieldType: 'single_checkbox',
            },
          ],
        },
      ]
    : fieldGroups

  const onInvalidSubmit = async () => {
    // Mark all required fields as touched
    const newTouched = {}
    newFieldGroups.forEach((group) => {
      'fields' in group &&
        group.fields?.forEach((field) => {
          if (field.required) {
            newTouched[field.name] = true
          }
        })
    })
  }

  useEffect(() => {
    if (!isLoading) {
      // Retriggers Zoominfo form complete functionality
      if (window._zi_fc?.fcRetrigger instanceof Function) {
        window._zi_fc.fcRetrigger()
      }

      trackEvent(`Hubspot Form Viewed`, {
        'Form Name': formName,
        'Form Hubspot ID': formId,
      })
    }
  }, [isLoading, formName, formId])

  if (isLoading) {
    return <FormLoading />
  }

  return (
    <ReactHookForm
      name={formName}
      hubspotFormId={formId}
      language={language}
      onSubmit={onSubmit}
      onInvalidSubmit={onInvalidSubmit}
      {...rest}
    >
      <HubspotFormContent
        formName={formName}
        fieldGroups={newFieldGroups}
        legalConsentOptions={legalConsentOptions}
        submitButtonText={submitButtonText}
        isSubmitting={isSubmitting}
      />
    </ReactHookForm>
  )
}

export default HubspotForm
