import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const FeaturedContent = ({ blok, backgroundColor }) => {
  return (
    <Stack
      {...storyblokEditable(blok)}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <Stack spacing={2} direction="row">
        <Typography level="body-sm">Featured Content</Typography>
        <Typography level="title-sm">{blok?.title}</Typography>
      </Stack>
      {blok?.cta?.map((currentCta) => {
        return (
          <StoryblokServerComponent
            key={currentCta._uid}
            blok={currentCta}
            backgroundColor={backgroundColor}
            color="neutral"
            size="sm"
          />
        )
      })}
    </Stack>
  )
}

export default FeaturedContent
