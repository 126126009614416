import colors from './colors'

// * Note that we do not pass NextJS link as the default component for the Link component because it requires href and there are times we want to render the look of a link but have it trigger something else like a modal or menu.
const joyTheme = {
  colorSchemes: {
    light: {
      palette: {
        common: {
          white: colors.common.white,
          black: colors.common.black,
        },
        primary: {
          50: colors.primary[50],
          100: colors.primary[100],
          200: colors.primary[200],
          300: colors.primary[300],
          400: colors.primary[400],
          500: colors.primary[500],
          600: colors.primary[600],
          700: colors.primary[700],
          800: colors.primary[800],
          900: colors.primary[900],
          solidBg: 'var(--joy-palette-primary-600)',
          solidActiveBg: 'var(--joy-palette-primary-800)',
          solidColor: 'var(--joy-palette-common-white)',
          solidDisabledBg: 'var(--joy-palette-neutral-100)',
          solidDisabledColor: 'var(--joy-palette-neutral-400)',
          solidHoverBg: 'var(--joy-palette-primary-700)',
          outlinedActiveBg: 'var(--joy-palette-primary-200)',
          outlinedBorder: 'var(--joy-palette-primary-400)',
          outlinedColor: 'var(--joy-palette-primary-600)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-primary-100)',
          plainActiveBg: 'var(--joy-palette-primary-200)',
          plainColor: 'var(--joy-palette-primary-600)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-primary-100)',
        },
        secondary: {
          50: colors.secondary[50],
          100: colors.secondary[100],
          200: colors.secondary[200],
          300: colors.secondary[300],
          400: colors.secondary[400],
          500: colors.secondary[500],
          600: colors.secondary[600],
          700: colors.secondary[700],
          800: colors.secondary[800],
          900: colors.secondary[900],
          solidBg: 'var(--joy-palette-secondary-300)',
          solidActiveBg: 'var(--joy-palette-secondary-700)',
          solidColor: 'var(--joy-palette-secondary-900)',
          solidDisabledBg: 'var(--joy-palette-neutral-600)',
          solidDisabledColor: 'var(--joy-palette-neutral-200)',
          solidHoverBg: 'var(--joy-palette-secondary-200)',
          outlinedActiveBg: 'var(--joy-palette-secondary-200)',
          outlinedBorder: 'var(--joy-palette-secondary-300)',
          outlinedColor: 'var(--joy-palette-secondary-300)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-secondary-100)',
          plainActiveBg: 'var(--joy-palette-secondary-600)',
          plainColor: 'var(--joy-palette-secondary-200)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-secondary-700)',
        },
        tertiary: {
          50: colors.tertiary[50],
          100: colors.tertiary[100],
          200: colors.tertiary[200],
          300: colors.tertiary[300],
          400: colors.tertiary[400],
          500: colors.tertiary[500],
          600: colors.tertiary[600],
          700: colors.tertiary[700],
          800: colors.tertiary[800],
          900: colors.tertiary[900],
          solidBg: 'var(--joy-palette-tertiary-300)',
          solidActiveBg: 'var(--joy-palette-tertiary-700)',
          solidColor: 'var(--joy-palette-tertiary-900)',
          solidDisabledBg: 'var(--joy-palette-neutral-600)',
          solidDisabledColor: 'var(--joy-palette-neutral-200)',
          solidHoverBg: 'var(--joy-palette-tertiary-200)',
          outlinedActiveBg: 'var(--joy-palette-tertiary-200)',
          outlinedBorder: 'var(--joy-palette-tertiary-300)',
          outlinedColor: 'var(--joy-palette-tertiary-300)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-tertiary-100)',
          plainActiveBg: 'var(--joy-palette-tertiary-600)',
          plainColor: 'var(--joy-palette-tertiary-200)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-tertiary-700)',
        },
        light: {
          50: colors.light[50],
          100: colors.light[100],
          200: colors.light[200],
          300: colors.light[300],
          400: colors.light[400],
          500: colors.light[500],
          600: colors.light[600],
          700: colors.light[700],
          800: colors.light[800],
          900: colors.light[900],
          solidBg: 'var(--joy-palette-light-50)',
          solidActiveBg: 'var(--joy-palette-light-200)',
          solidColor: 'var(--joy-palette-neutral-900)',
          solidDisabledBg: 'var(--joy-palette-neutral-600)',
          solidDisabledColor: 'var(--joy-palette-neutral-200)',
          solidHoverBg: 'var(--joy-palette-light-100)',
          outlinedActiveBg: 'var(--joy-palette-light-800)',
          outlinedBorder: 'var(--joy-palette-light-200)',
          outlinedColor: 'var(--joy-palette-light-50)',
          outlinedDisabledBorder: 'var( --joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
          outlinedHoverBg: 'var(--joy-palette-light-700)',
          plainActiveBg: 'var(--joy-palette-light-800)',
          plainColor: 'var(--joy-palette-light-50)',
          plainDisabledColor: 'var(--joy-palette-neutral-400)',
          plainHoverBg: 'var(--joy-palette-light-700)',
          softColor: 'var(--joy-palette-neutral-900)',
          softBg: 'var(--joy-palette-light-50)',
          softActiveBg: 'var(--joy-palette-light-300)',
          softHoverBg: 'var(--joy-palette-light-100)',
        },
        neutral: {
          50: colors.neutral[50],
          100: colors.neutral[100],
          200: colors.neutral[200],
          300: colors.neutral[300],
          400: colors.neutral[400],
          500: colors.neutral[500],
          600: colors.neutral[600],
          700: colors.neutral[700],
          800: colors.neutral[800],
          900: colors.neutral[900],
        },
        info: {
          50: colors.info[50],
          100: colors.info[100],
          200: colors.info[200],
          300: colors.info[300],
          400: colors.info[400],
          500: colors.info[500],
          600: colors.info[600],
          700: colors.info[700],
          800: colors.info[800],
          900: colors.info[900],
          solidBg: 'var(--joy-palette-info-400)',
          solidActiveBg: 'var(--joy-palette-info-500)',
          outlinedBorder: 'var(--joy-palette-info-500)',
          outlinedColor: 'var(--joy-palette-info-700)',
          outlinedActiveBg: 'var(--joy-palette-info-100)',
          softColor: 'var(--joy-palette-info-800)',
          softBg: 'var(--joy-palette-info-200)',
          softActiveBg: 'var(--joy-palette-info-300)',
          softHoverBg: 'var(--joy-palette-info-100)',
          plainColor: 'var(--joy-palette-info-700)',
          plainActiveBg: 'var(--joy-palette-info-100)',
        },
        danger: {
          50: colors.danger[50],
          100: colors.danger[100],
          200: colors.danger[200],
          300: colors.danger[300],
          400: colors.danger[400],
          500: colors.danger[500],
          600: colors.danger[600],
          700: colors.danger[700],
          800: colors.danger[800],
          900: colors.danger[900],
        },
        success: {
          50: colors.success[50],
          100: colors.success[100],
          200: colors.success[200],
          300: colors.success[300],
          400: colors.success[400],
          500: colors.success[500],
          600: colors.success[600],
          700: colors.success[700],
          800: colors.success[800],
          900: colors.success[900],
        },
        warning: {
          50: colors.warning[50],
          100: colors.warning[100],
          200: colors.warning[200],
          300: colors.warning[300],
          400: colors.warning[400],
          500: colors.warning[500],
          600: colors.warning[600],
          700: colors.warning[700],
          800: colors.warning[800],
          900: colors.warning[900],
        },
        text: {
          icon: 'var(--joy-palette-neutral-500)',
          primary: 'var(--joy-palette-neutral-900)',
          secondary: 'var(--joy-palette-neutral-700)',
          tertiary: 'var(--joy-palette-neutral-500)',
        },
        textOnDark: {
          icon: 'var(--joy-palette-neutral-500)',
          primary: 'var(--joy-palette-light-50)',
          secondary: 'var(--joy-palette-light-100)',
          tertiary: 'var(--joy-palette-light-200)',
        },
      },
    },
  },
  components: {
    JoyIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    JoyTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: '600',
            backgroundColor: 'inherit',
          },
          '&.MuiTab-colorPrimary': {
            color: `${colors.neutral[600]} !important`,
          },
          '&.Mui-selected&.MuiTab-colorPrimary': {
            color: `${colors.primary[500]} !important`,
          },
          '--Tab-indicatorThickness': '3px',
        },
      },
    },
    JoyRadioGroup: {
      styleOverrides: {
        root: {
          margin: '0',
          alignItems: 'flex-start',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: {
          width: 'fit-content',
        },
      },
    },
    JoyListItemDecorator: {
      styleOverrides: {
        root: {
          alignSelf: 'flex-start',
          paddingTop: '6px',
        },
      },
      defaultProps: {
        sx: { svg: { width: '18.33px', height: '18.33px' } },
      },
    },
    JoyModal: {
      styleOverrides: {
        root: {
          // One larger than header navigation
          zIndex: 99999999999,
        },
      },
    },
    JoyTable: {
      styleOverrides: {
        root: {
          '--TableCell-paddingY': '16px',
          '--TableCell-paddingX': '12px',
          '--Table-headerUnderlineThickness': '1px',
          '--TableCell-borderColor': colors.neutral[200],
          'thead tr': {
            position: 'relative',
            boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
  },
}

export default joyTheme
