import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import UICard from '@audioeye-marketing/ui/src/components/Card'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import determinePlanCardColor from '@/lib/determine-plan-card-color'

const PlanCard = ({ blok, backgroundColor }) => {
  const planCardColor = determinePlanCardColor({ backgroundColor, subBackgroundColor: blok?.background })

  return (
    <UICard
      {...storyblokEditable(blok)}
      noBorderRadius
      variant="plain"
      className={`blok-plan-card blok-plan-card-${blok?.background}`}
      sx={{
        ...planCardColor,
        padding: 3,
        width: '100%',
        gap: '0',
        maxWidth: '600px',
        backgroundColor: `${planCardColor} !important`,
        border: blok?.background === 'dark' ? `1px solid ${planCardColor}` : '1px solid var(--joy-palette-divider)',
        '--joy-palette-background-surface': `${planCardColor} !important`,
      }}
    >
      <Stack spacing={2} justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack spacing={2.5}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}>
            {blok?.chip?.map((currentChip) => (
              <StoryblokServerComponent
                key={currentChip._uid}
                blok={currentChip}
                backgroundColor={backgroundColor}
                subBackgroundColor={blok?.background}
              />
            ))}
          </Box>
          <Stack spacing={1.5}>
            {Boolean(blok?.plan_name) && (
              // Set to title-md so it gets primaryOnDark and primary color text instead of secondary
              <Typography displayMedium level="title-md">
                {blok?.plan_name}
              </Typography>
            )}
            {Boolean(blok?.sub_text) && <Typography level="body-xs">{blok?.sub_text}</Typography>}
          </Stack>
        </Stack>
        {blok?.details?.map((currentDetails) => (
          <StoryblokServerComponent
            key={currentDetails._uid}
            blok={currentDetails}
            backgroundColor={backgroundColor}
            options={{
              NODE_PARAGRAPH: { level: 'body-sm' },
              NODE_UL: {
                sx: { margin: '0 !important' },
              },
            }}
          />
        ))}
        {blok?.cta?.map((currentCta) => (
          <StoryblokServerComponent
            key={currentCta?._uid}
            blok={currentCta}
            backgroundColor={backgroundColor}
            subBackgroundColor={blok?.background}
            size="sm"
          />
        ))}
      </Stack>
    </UICard>
  )
}

export default PlanCard
