'use client'

import React, { useState, useEffect } from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Container from '@audioeye-marketing/ui/src/components/Container'
import { checkExpiredData, persistData, getPersistedData } from '@/lib/local-storage-helpers'
import fetchAirtableData from '@/lib/fetch-airtable-data'
import EarningsTable from '@/components/EarningsTable'
import useAirtableBase from '@/hooks/useAirtableBase'

const Airtable = ({ blok, backgroundColor }) => {
  const [isData, setIsData] = useState()
  const [bsData, setBsData] = useState()
  const [gaapData, setGaapData] = useState()
  const [isSkipUnaudited, setIsSkipUnaudited] = useState(false)
  const [bsSkipUnaudited, setBsSkipUnaudited] = useState(false)
  const [gaapSkipUnaudited, setGaapSkipUnaudited] = useState(false)
  const airtableBase = useAirtableBase(blok?.airtable_id)

  const checkUnauditedHeader = (unauditedData) => {
    if (!unauditedData) return null
    const unauditedDataArr = Object.values(unauditedData).filter((item) => item !== undefined)
    if (unauditedDataArr[0] === 'skip') return true
    return false
  }

  useEffect(() => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsKey = `earnings-data-${blok?.airtable_id}`
    const lsEarningsData = ls[lsKey]

    async function fetchNewData() {
      const isRes = await fetchAirtableData(airtableBase, 'IS')

      if (isRes) {
        setIsSkipUnaudited(checkUnauditedHeader(isRes['<unaudited>']))
        setIsData(isRes)
      }

      const bsRes = await fetchAirtableData(airtableBase, 'BS')
      if (bsRes) {
        setBsSkipUnaudited(checkUnauditedHeader(bsRes['<unaudited>']))
        setBsData(bsRes)
      }

      const gaapRes = await fetchAirtableData(airtableBase, 'Non-GAAP')
      if (gaapRes) {
        setGaapSkipUnaudited(checkUnauditedHeader(gaapRes['<unaudited>']))
        setGaapData(gaapRes)
      }

      persistData(
        Object.assign(ls, {
          [`${lsKey}`]: {
            timestamp: Date.now(),
            bsData: bsRes,
            isData: isRes,
            gaapData: gaapRes,
          },
        })
      )
    }

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setBsData(lsEarningsData.bsData)
      setIsData(lsEarningsData.isData)
      setGaapData(lsEarningsData.gaapData)
    } else {
      fetchNewData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run once
  }, [])

  return (
    <Stack spacing={8} direction="column" {...storyblokEditable(blok)}>
      <Box>
        {Boolean(isData) && (
          <Box>
            <Container constraint="md">
              <Typography textAlign="center" level="h3" sx={{ mb: 7.5 }}>
                AUDIOEYE, INC.
                <br />
                {blok?.is_table_header || 'STATEMENTS OF OPERATIONS'} <br />
                {!isSkipUnaudited && '(unaudited)'}
              </Typography>
            </Container>
            <EarningsTable tableData={isData} skipUnauditedHeader={isSkipUnaudited} />
          </Box>
        )}
        {Boolean(bsData) && (
          <Box sx={{ mt: 12.5 }}>
            <Container constraint="md">
              <Typography textAlign="center" level="h3" sx={{ mb: 7.5 }}>
                AUDIOEYE, INC.
                <br />
                {blok?.bs_table_header || 'BALANCE SHEETS'}
                <br />
                {!bsSkipUnaudited && '(unaudited)'}
              </Typography>
            </Container>
            <EarningsTable tableData={bsData} skipUnauditedHeader={bsSkipUnaudited} />
          </Box>
        )}
        {Boolean(gaapData) && (
          <Box sx={{ mt: 12.5 }}>
            <Container constraint="md">
              <Typography textAlign="center" level="h3" sx={{ mb: 7.5 }}>
                AUDIOEYE, INC.
                <br />
                {blok?.gaap_table_header || 'RECONCILIATIONS OF GAAP to NON-GAAP FINANCIAL MEASURES'}
                <br />
                {!gaapSkipUnaudited && '(unaudited)'}
              </Typography>
            </Container>
            <EarningsTable tableData={gaapData} skipUnauditedHeader={gaapSkipUnaudited} />
          </Box>
        )}
      </Box>
      {blok?.legal?.map((currentRichText) => (
        <StoryblokServerComponent
          key={currentRichText?._uid}
          blok={currentRichText}
          backgroundColor={backgroundColor}
        />
      ))}
    </Stack>
  )
}

export default Airtable
