import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Box from '@audioeye-marketing/ui/src/components/Box'
import translateSectionStyles from '@/lib/translate-section-styles'

const AccordionContent = ({ blok, rels, headingLevel }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const align = blok?.left_aligned ? 'flex-start' : 'center'
  const alignLeft = align === 'flex-start'
  const accordion = blok?.accordion?.[0]?.component === 'Accordion'
  const accordionStyles = accordion ? { display: 'flex', justifyContent: alignLeft ? 'flex-start' : 'center' } : {}

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} id={blok?.anchor?.[0]?.anchor_id}>
      <Container constraint="lg">
        <Stack spacing={5}>
          {blok?.text_content?.map((currentTextContent) => (
            <Container
              key={currentTextContent?._uid}
              leftAlign={alignLeft}
              constraint={alignLeft && !accordion ? 'md' : alignLeft ? 'md' : 'md'}
            >
              <StoryblokServerComponent
                blok={currentTextContent}
                rels={rels}
                alignItems={align}
                backgroundColor={sectionStyles?.backgroundColor}
                headingLevel={headingLevel}
              />
            </Container>
          ))}

          <Box sx={{ ...accordionStyles }}>
            {blok?.accordion?.map((currentAccordion) => (
              <StoryblokServerComponent
                key={currentAccordion?._uid}
                blok={currentAccordion}
                rels={rels}
                backgroundColor={sectionStyles?.backgroundColor}
              />
            ))}
          </Box>
        </Stack>
      </Container>
    </Section>
  )
}

export default AccordionContent
