import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import translateSectionStyles from '@/lib/translate-section-styles'

const TableContent = ({ blok, rels, headingLevel }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])
  const align = blok?.left_aligned ? 'flex-start' : 'center'
  const alignLeft = align === 'flex-start'

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} id={blok?.anchor?.[0]?.anchor_id}>
      <Container constraint="lg" sx={{ mb: 8 }}>
        <Container leftAlign={alignLeft} constraint={alignLeft ? 'sm' : 'md'}>
          {blok?.text_content?.map((currentTextContent) => (
            <StoryblokServerComponent
              key={currentTextContent?._uid}
              blok={currentTextContent}
              rels={rels}
              alignItems={align}
              headingLevel={headingLevel}
              backgroundColor={sectionStyles?.backgroundColor}
            />
          ))}
        </Container>
      </Container>
      <Container constraint="lg">
        {blok?.table?.map((currentTable) => (
          <StoryblokServerComponent
            key={currentTable?._uid}
            blok={currentTable}
            rels={rels}
            backgroundColor={sectionStyles?.backgroundColor}
          />
        ))}
      </Container>
    </Section>
  )
}

export default TableContent
