import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Section from '@audioeye-marketing/ui/src/components/Section'
import Container from '@audioeye-marketing/ui/src/components/Container'
import translateSectionStyles from '@/lib/translate-section-styles'

const RichTextContent = ({ blok }) => {
  const sectionStyles = translateSectionStyles(blok?.section_styles?.[0])

  return (
    <Section {...storyblokEditable(blok)} {...sectionStyles} id={blok?.anchor?.[0]?.anchor_id}>
      <Container constraint="sm">
        {blok?.rich_text?.map((currentRichText) => (
          <StoryblokServerComponent
            key={currentRichText?._uid}
            blok={currentRichText}
            backgroundColor={sectionStyles?.backgroundColor}
          />
        ))}
      </Container>
    </Section>
  )
}

export default RichTextContent
