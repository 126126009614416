import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Grid from '@audioeye-marketing/ui/src/components/Grid'

const MegaMenuRow = ({ blok, rels, underline }) => {
  return (
    <Grid
      {...storyblokEditable(blok)}
      container
      sx={{ width: '100%', borderBottom: underline ? '1px solid var(--joy-palette-divider)' : '' }}
    >
      {blok?.columns.map((currentColumn) => {
        return (
          <StoryblokServerComponent key={currentColumn?._uid} blok={currentColumn} rels={rels} underline={underline} />
        )
      })}
    </Grid>
  )
}

export default MegaMenuRow
