import NextLink from 'next/link'
import { storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import trackEvent from '@audioeye-marketing/ui/src/lib/track-event'
import Card from '@audioeye-marketing/ui/src/components/Card'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Divider from '@audioeye-marketing/ui/src/components/Divider'

const JobOpenings = async ({ blok }) => {
  const jobsResponse = await fetch('https://boards-api.greenhouse.io/v1/boards/audioeye/departments', {
    next: { revalidate: 3600 },
  })

  const data = await jobsResponse.json()
  const departments = data?.departments || []
  const filteredDepartments = departments.filter((department) => department.jobs?.length > 0)

  if (!jobsResponse.ok) {
    trackEvent(`API Exception`, {
      Label: 'Greenhouse job board API general error',
      'Full Exception': JSON.stringify(jobsResponse),
    })

    return <Typography displaySmall>Error loading job openings. Please check back soon! 👋</Typography>
  }

  if (filteredDepartments.length === 0) {
    return <Typography displaySmall>There are no current openings. Please check back soon! 👋</Typography>
  }

  return (
    <Box {...storyblokEditable(blok)}>
      {filteredDepartments.map((currentDepartment, index) => {
        const divider = index > 0 && index + 1 <= filteredDepartments.length && (
          <Divider sx={{ mt: { xs: 5, md: 10 }, mb: { xs: 5, md: 10 } }} />
        )

        return (
          <Stack
            key={currentDepartment?.id}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            justifyContent="space-between"
          >
            <Stack direction="column" spacing={10} sx={{ width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                {divider}
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
                  <Typography level="h3" sx={{ flex: 1, mb: 3 }}>
                    {currentDepartment?.name}
                  </Typography>
                  <Stack spacing={3} sx={{ flex: 1 }}>
                    {currentDepartment.jobs.map((job) => (
                      <Card
                        key={job.id}
                        className="job-openings-card"
                        noBorderRadius
                        variant="plain"
                        component={NextLink}
                        href={job?.absolute_url}
                        target="_blank"
                        sx={{
                          transition: 'box-shadow 125ms ease-in-out, transform 125ms ease-in-out',
                          textDecoration: 'none',
                          boxShadow: 'none',
                          ':hover': {
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <Stack spacing={2}>
                          <Typography level="title-lg">{job?.title}</Typography>
                          <Typography level="body-md">{job?.location?.name}</Typography>
                        </Stack>
                      </Card>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        )
      })}
    </Box>
  )
}

export default JobOpenings
