import React from 'react'
import NextLink from 'next/link'
import determineStoryblokLink from '@/lib/determine-storyblok-link'

const Link = ({ blok, trigger }) => {
  const href = determineStoryblokLink({ blok })
  const target = blok?.link?.target
  const cloneProps = {
    className: [
      trigger.props.className,
      // * Necessary for CookieYes. All links will be considered a CookieYes Link if the url is #
      blok?.link?.cached_url === '#' ? 'cky-banner-element' : null,
    ],
  }

  if (trigger?.props?.slotProps) {
    cloneProps.slotProps = {
      ...trigger.props.slotProps,
      action: {
        ...trigger.props.slotProps?.action,
        href,
        target,
      },
    }
  } else {
    cloneProps.href = href
    cloneProps.target = target
    cloneProps.component = NextLink
  }

  const clonedTrigger = React.cloneElement(trigger, {
    ...cloneProps,
  })

  return clonedTrigger
}

export default Link
