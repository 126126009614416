import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIList from '@audioeye-marketing/ui/src/components/List'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const List = ({ blok, backgroundColor, size = 'md', removeListItemTargetBlankIcon }) => {
  return (
    <UIList {...storyblokEditable(blok)}>
      <Stack spacing={1}>
        {blok?.items?.map((currentItem) => {
          return (
            <StoryblokServerComponent
              key={currentItem?._uid}
              blok={currentItem}
              backgroundColor={backgroundColor}
              size={size}
              removeListItemTargetBlankIcon={removeListItemTargetBlankIcon}
            />
          )
        })}
      </Stack>
    </UIList>
  )
}

export default List
