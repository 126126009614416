import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'

const TableRow = ({ blok, backgroundColor }) => {
  return (
    <tr {...storyblokEditable(blok)}>
      {blok?.cells?.map((currentCells, index) => (
        <StoryblokServerComponent
          key={currentCells?._uid}
          blok={currentCells}
          firstCell={index === 0}
          backgroundColor={backgroundColor}
        />
      ))}
    </tr>
  )
}

export default TableRow
