import colors from '@audioeye-marketing/ui/src/theme/colors'

/**
 * @param {string} backgroundColor - string representing the background color
 * @param {string} subBackgroundColor - string representing the sub background color (surface within a section)
 * @return {string} - string representing the planCard color
 */
const determinePlanCardColor = ({ backgroundColor, subBackgroundColor = 'medium' }) => {
  const planCardColors = {
    [colors.neutral[50]]: {
      light: 'transparent',
      medium: colors.common.white,
      dark: colors.primary[900],
    },
    [colors.common.white]: {
      light: 'transparent',
      medium: colors.neutral[50],
      dark: colors.primary[900],
    },
    [colors.common.black]: {
      light: 'transparent',
      medium: colors.light[900],
      dark: colors.common.white,
    },
    [colors.primary[900]]: {
      light: 'transparent',
      medium: colors.light[900],
      dark: colors.common.white,
    },
    [colors.secondary[900]]: {
      light: 'transparent',
      medium: colors.light[900],
      dark: colors.common.white,
    },
    [colors.tertiary[900]]: {
      light: 'transparent',
      medium: colors.light[900],
      dark: colors.common.white,
    },
    [colors.info[900]]: {
      light: 'transparent',
      medium: colors.light[900],
      dark: colors.common.white,
    },
  }

  return planCardColors?.[backgroundColor]?.[subBackgroundColor] || planCardColors[colors.common.white].medium
}

export default determinePlanCardColor
