import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Skeleton from '@audioeye-marketing/ui/src/components/Skeleton'
import logger from '@/lib/logger'

const Fallback = ({ height, width, noSkeleton }) => (
  <Box sx={{ width, height }}>{Boolean(!noSkeleton) && <Skeleton width={width} height={height} />}</Box>
)

const withFallback = (FallbackComponent, props) => {
  const Component = () => <FallbackComponent {...props} />
  Component.displayName = `WithFallback(${FallbackComponent.displayName || FallbackComponent.name || 'Component'})`
  return Component
}

const createDynamicIcon = (iconName, NonLoadingFallback, LoadingFallback) => {
  return dynamic(
    async () => {
      try {
        const icons = await import('@audioeye/ui-icons/Line')
        if (iconName in icons) {
          return icons[iconName]
        }

        logger.warn(`Icon "${iconName}" not found, using fallback.`)
        return NonLoadingFallback
      } catch (error) {
        logger.error('Error loading icons:', error)
        return NonLoadingFallback
      }
    },
    {
      loading: LoadingFallback,
      ssr: false, // Disable server-side rendering for icons
    }
  )
}

const Icon = ({ blok, height = 40, width = 40, strokeWidth = 1 }) => {
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]
  const iconName = blok?.icon?.icon

  const NonLoadingFallback = useMemo(() => withFallback(Fallback, { height, width, noSkeleton: true }), [height, width])
  const LoadingFallback = useMemo(() => withFallback(Fallback, { height, width }), [height, width])

  const DynamicIcon = useMemo(() => {
    if (!iconName) return null
    return createDynamicIcon(iconName, NonLoadingFallback, LoadingFallback)
  }, [iconName, NonLoadingFallback, LoadingFallback])

  const FinalIcon = useMemo(() => {
    if (!blok?.icon) return null
    return (
      <Box
        {...storyblokEditable(blok)}
        className="blok-icon"
        sx={{
          cursor: trigger ? 'pointer' : 'default',
          width,
          height,
          path: {
            strokeWidth,
          },
        }}
      >
        {Boolean(DynamicIcon) && <DynamicIcon height={height} width={width} />}
      </Box>
    )
  }, [blok, trigger, width, height, strokeWidth, DynamicIcon])

  return triggerBlok ? <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalIcon} /> : FinalIcon
}

export default Icon
