import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIGrid from '@audioeye-marketing/ui/src/components/Grid'

const Grid = ({ blok, rels, backgroundColor, imagePriority }) => {
  const spacing = 4
  const gridSx = {}

  if (blok?.columns === 'none') {
    // Takes the width of the md Section container minus the padding of spacing={4}
    gridSx.width = `${864 / blok?.items?.length - 16}px`
    gridSx.display = 'flex'
    gridSx.justifyContent = 'center'
  }

  if (blok?.bento_box) {
    gridSx.flexGrow = { sm: '1 !important', md: '0 !important' }
  }

  const baseGridProps = blok?.columns !== 'none' ? { xs: 12, sm: 6, md: 12 / blok?.columns } : {}

  return (
    <UIGrid
      {...storyblokEditable(blok)}
      container
      spacing={spacing}
      justifyContent={blok?.justify_content}
      alignItems="stretch"
      // Negative margin to offset the spacing of the grid items and align to text content
      sx={{ marginLeft: `-${spacing * 4}px !important`, marginRight: `-${spacing * 4}px !important` }}
    >
      {blok?.items?.map((currentItem, index) => {
        const remainingItems = blok?.items?.length % blok?.columns
        const finalGridProps = { ...baseGridProps }

        if (blok?.reverse_columns) {
          if (remainingItems > 0 && index < remainingItems) {
            finalGridProps.md = 12 / remainingItems
          }
        } else if (blok?.bento_box && remainingItems > 0 && index >= blok?.items?.length - remainingItems) {
          finalGridProps.md = 12 / remainingItems
        }

        return (
          <UIGrid key={currentItem?._uid} display="flex" justifyContent="center" sx={gridSx} {...finalGridProps}>
            <StoryblokServerComponent
              blok={currentItem}
              rels={rels}
              backgroundColor={backgroundColor}
              imagePriority={imagePriority}
            />
          </UIGrid>
        )
      })}
    </UIGrid>
  )
}

export default Grid
