import { render, NODE_HEADING, NODE_PARAGRAPH, MARK_LINK, NODE_LI, NODE_UL } from 'storyblok-rich-text-react-renderer'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Link from '@audioeye-marketing/ui/src/components/Link'
import List from '@audioeye-marketing/ui/src/components/List'
import ListItem from '@audioeye-marketing/ui/src/components/ListItem'
import ListItemContent from '@audioeye-marketing/ui/src/components/ListItemContent'
import ListItemDecorator from '@audioeye-marketing/ui/src/components/ListItemDecorator'
import Check from '@audioeye/ui-icons/Line/General/Check'
import Plus from '@audioeye/ui-icons/Line/General/Plus'
import Star05 from '@audioeye/ui-icons/Line/Shapes/Star05'
import Video from '@/components/storyblok/items/Video'
import determineLinkColor from '@/lib/determine-link-color'

const renderRichText = ({ document, options, backgroundColor }) => {
  const color = determineLinkColor({ backgroundColor })

  return render(document, {
    markResolvers: {
      [MARK_LINK]: (children, { linktype, href, target }) => {
        return (
          <Link href={linktype === 'email' ? `mailto:${href}` : href} target={target} color={color}>
            {children}
          </Link>
        )
      },
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        return (
          <Typography level={`h${level}`} {...options?.NODE_HEADING}>
            {children}
          </Typography>
        )
      },
      [NODE_PARAGRAPH]: (children) => {
        return (
          <Typography level="body-lg" {...options?.NODE_PARAGRAPH}>
            {children}
          </Typography>
        )
      },
      [NODE_UL]: (children) => {
        return (
          <List {...options?.NODE_UL} sx={{ textAlign: 'left' }}>
            {children}
          </List>
        )
      },
      [NODE_LI]: (children) => {
        let checkmark = <Check />

        if (options?.checkmark === 'plus') {
          checkmark = <Plus />
        } else if (options?.checkmark === 'star') {
          checkmark = <Star05 />
        }

        return (
          <ListItem>
            <ListItemDecorator>{checkmark}</ListItemDecorator>
            <ListItemContent>{children}</ListItemContent>
          </ListItem>
        )
      },
    },
    blokResolvers: {
      Video: (props) => {
        return <Video blok={props} />
      },
    },
  })
}

export default renderRichText
