import { Fragment } from 'react'
import Table from '@audioeye-marketing/ui/src/components/Table'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Box from '@audioeye-marketing/ui/src/components/Box'

const EarningsTable = ({ tableData, skipUnauditedHeader }) => {
  if (!tableData) return null

  const rowDataArr = Object.entries(tableData)

  // Remove <unaudited> formatting util row
  if (skipUnauditedHeader) rowDataArr.shift()
  const rowData = rowDataArr
    .filter((data) => data[0] !== 'colNames')
    .map((data) => ({ rowHeader: data[0], rowData: data[1] }))

  const fourColTable = tableData.colNames?.length >= 3
  const formatCurrency = (currency) =>
    // eslint-disable-next-line prefer-named-capture-group -- don't want to re-write and potentially lose its functionality
    currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

  const reorderColumns = (colNames) => {
    if (fourColTable) {
      colNames.sort()
      return ([colNames[0], colNames[1], colNames[2], colNames[3]] = [
        colNames[1],
        colNames[0],
        colNames[3],
        colNames[2],
      ])
    }
    return tableData.colNames
  }

  const reorderedColumnData = reorderColumns(tableData.colNames)

  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <Table
        size="lg"
        sx={{
          minWidth: '600px',
          '& thead th:nth-child(1)': { width: { xs: '35%', md: '50%' } },
          '& tr > *:not(:first-child)': { textAlign: 'right' },
          '& th': { backgroundColor: 'transparent !important' },
          '& thead tr': { boxShadow: 'none !important' },
          '--TableCell-paddingY': '12px',
          '--TableCell-paddingX': '8px',
        }}
      >
        {Boolean(tableData?.colNames) && (
          <thead>
            <tr>
              <th style={{ border: 'none' }} />
              {fourColTable
                ? reorderedColumnData.map((colName, i) => {
                    return (
                      <Fragment key={colName}>
                        {i % 2 === 0 && (
                          <th key={colName} colSpan={2} style={{ border: 'none' }}>
                            <Typography level="title-lg" role="presentation">
                              {colName.split(',')[0].split(' ').slice(0, 3).join(' ')}
                              <br />
                              {colName.split(',')[0].split(' ').slice(3).join(' ')}
                            </Typography>
                          </th>
                        )}
                      </Fragment>
                    )
                  })
                : reorderedColumnData.map((colName) => (
                    <th key={colName} style={{ border: 'none' }}>
                      <Typography level="title-lg">{colName.split(',')[0]}</Typography>
                    </th>
                  ))}
            </tr>
            <tr>
              <th style={{ border: 'none' }} />
              {fourColTable
                ? reorderedColumnData.map((colName) => (
                    <th key={colName} aria-label={colName}>
                      <Typography level="title-md">{colName.split(', ')[1]}</Typography>
                    </th>
                  ))
                : reorderedColumnData.map((colName) => (
                    <th key={colName}>
                      <Typography level="title-md">{colName.split(',')[1]}</Typography>
                    </th>
                  ))}
            </tr>
            <tr>
              <td colSpan={tableData?.colNames.length + 1}>(in thousands, except per share data)</td>
            </tr>
          </thead>
        )}
        <tbody>
          {rowData.map((row) =>
            row.rowHeader.includes('undefined') ? (
              // Spacer rows
              <tr role="presentation" key={row.rowHeader}>
                <td style={{ border: 'none' }} />
              </tr>
            ) : (
              <tr key={row.rowHeader}>
                {/* Strip out header utility text */}
                {row.rowHeader.includes('<header>') || row.rowHeader.includes('<total>') ? (
                  row.rowHeader.includes('<header>') ? (
                    <td>
                      <Typography level="body-lg">
                        <b>{row.rowHeader.split('<header>')[1]}</b>
                      </Typography>
                    </td>
                  ) : (
                    <td>{row.rowHeader.split('<total>')[1]}</td>
                  )
                ) : row.rowHeader.includes('<subtotal>') ? (
                  <td>
                    {row.rowHeader.includes('_')
                      ? row.rowHeader.split('_')[0].split('<subtotal>')[1]
                      : row.rowHeader.split('<subtotal>')[1]}
                  </td>
                ) : (
                  <td>{row.rowHeader.includes('_') ? row.rowHeader.split('_')[0] : row.rowHeader}</td>
                )}
                {Boolean(tableData?.colNames) &&
                  Boolean(row.rowData) &&
                  reorderedColumnData.map((colName) =>
                    !row.rowHeader.includes('<header>') ? (
                      <td
                        aria-label={row.rowData?.[colName]?.toString()?.includes('(') ? 'negative value' : null}
                        key={row.rowHeader}
                        style={row.rowHeader.includes('<total>') ? { borderBottom: '4px double black' } : null}
                      >
                        {formatCurrency(row.rowData[colName])}
                      </td>
                    ) : (
                      <td role="presentation" key={row.rowHeader} />
                    )
                  )}
              </tr>
            )
          )}
        </tbody>
      </Table>
    </Box>
  )
}

export default EarningsTable
