'use client'

import { useState, useEffect } from 'react'
import { useSearchParams } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react'
import Contrast from 'get-contrast'
import { HexColorPicker } from 'react-colorful'
import Form from '@audioeye-marketing/ui/src/components/Form'
import Card from '@audioeye-marketing/ui/src/components/Card'
import Grid from '@audioeye-marketing/ui/src/components/Grid'
import Tooltip from '@audioeye-marketing/ui/src/components/Tooltip'
import FormControl from '@audioeye-marketing/ui/src/components/FormControl'
import FormLabel from '@audioeye-marketing/ui/src/components/FormLabel'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Input from '@audioeye-marketing/ui/src/components/Input'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Slider from '@audioeye-marketing/ui/src/components/Slider'
import Switch from '@audioeye-marketing/ui/src/components/Switch'
import Divider from '@audioeye-marketing/ui/src/components/Divider'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Chip from '@audioeye-marketing/ui/src/components/Chip'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Sun from '@audioeye/ui-icons/Line/Weather/Sun'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import InfoCircle from '@audioeye/ui-icons/Line/General/InfoCircle'
import Copy04 from '@audioeye/ui-icons/Line/General/Copy04'
import Check from '@audioeye/ui-icons/Line/General/Check'
import XClose from '@audioeye/ui-icons/Line/General/XClose'
import ShieldTick from '@audioeye/ui-icons/Line/Security/ShieldTick'
import CheckVerified01 from '@audioeye/ui-icons/Line/General/CheckVerified01'
import SwitchHorizontal01 from '@audioeye/ui-icons/Line/Arrows/SwitchHorizontal01'
import AlertOctagon from '@audioeye/ui-icons/Line/Alerts/AlertOctagon'
import CheckCircle from '@audioeye/ui-icons/Line/General/CheckCircle'
import hexToHSL from '@/lib/hex-to-hsl'
import hslToHex from '@/lib/hsl-to-hex'
import copyTextToClipboard from '@/lib/copy-text-to-clipboard.js'

const ColorContrastChecker = ({ blok }) => {
  const searchParams = useSearchParams()
  const [foregroundColor, setForegroundColor] = useState('#000000')
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
  const [foregroundLuminosity, setForegroundLuminosity] = useState('0')
  const [backgroundLuminosity, setBackgroundLuminosity] = useState('100')
  const [foregroundColorPickerOpen, setForegroundColorPickerOpen] = useState(false)
  const [backgroundColorPickerOpen, setBackgroundColorPickerOpen] = useState(false)
  const [ratio, setRatio] = useState(21)
  const [level, setLevel] = useState('GREAT')
  const [pass, setPass] = useState(true)
  const [grayscale, setGrayscale] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const smallTextAAPassed = ratio >= 4.5
  const smallTextAAAPassed = ratio >= 7
  const largeTextAAPassed = ratio >= 3
  const largeTextAAAPassed = ratio >= 4.5
  const graphicsPassed = ratio >= 3
  let complianceLevel

  if (smallTextAAPassed && smallTextAAAPassed && largeTextAAPassed && largeTextAAAPassed && graphicsPassed) {
    complianceLevel = {
      message: (
        <Typography>
          <b>You meet Level AAA contrast minimums</b> across all text sizes & UI elements.
        </Typography>
      ),
      chip: (
        <Chip size="lg" startDecorator={<ShieldTick />} color="success" variant="soft">
          Great
        </Chip>
      ),
    }
  } else if (smallTextAAPassed && largeTextAAPassed && graphicsPassed) {
    complianceLevel = {
      message: (
        <Typography>
          <b>You meet Level AA contrast minimums</b> across all text sizes & UI elements. Increase the contrast level to
          achieve level AAA.
        </Typography>
      ),
      chip: (
        <Chip size="lg" startDecorator={<CheckVerified01 />} color="info" variant="soft">
          Good
        </Chip>
      ),
    }
  } else if (largeTextAAPassed && graphicsPassed) {
    complianceLevel = {
      message: (
        <Typography>
          <b>You meet Level AA contrast minimums</b> for large text & UI elements. Increase the contrast level to
          achieve level AA in small text.
        </Typography>
      ),
      chip: (
        <Chip size="lg" startDecorator={<CheckCircle />} color="warning" variant="soft">
          Ok
        </Chip>
      ),
    }
  } else {
    complianceLevel = {
      message: (
        <Typography>
          You <b>do not meet</b> Level AA contrast minimums across any text sizes & UI elements. Increase the contrast
          level to achieve level AA.
        </Typography>
      ),
      chip: (
        <Chip size="lg" startDecorator={<AlertOctagon />} color="danger" variant="soft">
          Poor
        </Chip>
      ),
    }
  }

  const handleShare = () => {
    copyTextToClipboard(new URL(window.location)).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    })
  }

  const determineLevel = (contrastRatio) => {
    if (contrastRatio < 3) return 'POOR'
    if (contrastRatio >= 3 && contrastRatio < 4.5) return 'OKAY'
    if (contrastRatio >= 4.5 && contrastRatio < 7) return 'GOOD'
    if (contrastRatio >= 7 && contrastRatio <= 21) return 'GREAT'
  }

  const checkValidHexInput = (input) => {
    // eslint-disable-next-line prefer-regex-literals -- want to keep functionality from old contrast checker
    const isValidHex = new RegExp('^[A-Fa-f0-9#]*$')
    return isValidHex.test(input)
  }

  const calculateRatio = (a, b) => {
    const newRatio = Contrast.ratio(a, b).toString().split('.')
    return newRatio.length > 1 ? `${newRatio[0]}.${newRatio[1].substring(0, 1)}` : newRatio[0]
  }

  useEffect(() => {
    const foregroundColor = searchParams.get('foreground_color')
    const backgroundColor = searchParams.get('background_color')
    if (foregroundColor) setForegroundColor(`#${foregroundColor}`)
    if (backgroundColor) setBackgroundColor(`#${backgroundColor}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run on initial load
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (foregroundColor) {
      queryParams.set('foreground_color', `${foregroundColor.split('#')[1]}`)
    }

    if (backgroundColor) {
      queryParams.set('background_color', `${backgroundColor.split('#')[1]}`)
    }

    window.history.replaceState({}, '', `?${queryParams.toString()}`)
  }, [backgroundColor, foregroundColor])

  useEffect(() => {
    const foregroundHsl = hexToHSL(foregroundColor)
    const backgroundHsl = hexToHSL(backgroundColor)
    const newForegroundLuminosity = parseFloat(foregroundHsl.split(',')[2].split('%')[0])
    const newBackgroundLuminosity = parseFloat(backgroundHsl.split(',')[2].split('%')[0])
    const newRatio = calculateRatio(foregroundHsl, backgroundHsl)
    setForegroundLuminosity(newForegroundLuminosity)
    setBackgroundLuminosity(newBackgroundLuminosity)
    setRatio(newRatio)
    setPass(Contrast.isAccessible(foregroundHsl, backgroundHsl))
    setLevel(determineLevel(newRatio))
  }, [foregroundColor, backgroundColor])

  return (
    <Stack spacing={5.25} {...storyblokEditable(blok)}>
      <Form>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={5}>
          <FormControl sx={{ flex: 3, width: '100%' }}>
            <FormLabel>Foreground Color</FormLabel>
            <Box sx={{ position: 'relative' }}>
              <Input
                placeholder="#000000"
                size="lg"
                sx={{ mb: 2 }}
                onChange={(e) => {
                  let value = e.target.value

                  if (!value.startsWith('#')) {
                    value = `#${value.replace(/#/g, '')}`
                  }

                  if (value.length <= 7 && checkValidHexInput(value)) {
                    setForegroundColor(value.toUpperCase())
                  }
                }}
                value={foregroundColor}
                endDecorator={
                  <Box
                    onClick={() => setForegroundColorPickerOpen(!foregroundColorPickerOpen)}
                    sx={{
                      borderRadius: '50%',
                      backgroundColor: foregroundColor,
                      width: '24px',
                      height: '24px',
                      border: `1px solid ${colors.neutral[300]}`,
                      cursor: 'pointer',
                    }}
                  />
                }
              />
              {Boolean(foregroundColorPickerOpen) && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '65px',
                    right: '10px',
                    zIndex: '9999',
                    '.react-colorful': {
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                      borderRadius: '8px',
                      background: 'white',
                      padding: 3,
                    },
                    '::before': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-10px',
                      right: '10px',
                      width: '0',
                      height: '0',
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderTop: '10px solid white',
                      zIndex: '9998',
                    },
                  }}
                >
                  <HexColorPicker
                    color={foregroundColor}
                    onChange={(color) => setForegroundColor(color.toUpperCase())}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') setForegroundColorPickerOpen(false)
                    }}
                  />
                  <Box
                    sx={{ cursor: 'pointer', padding: '2px', position: 'absolute', top: '5px', right: '5px' }}
                    onClick={() => setForegroundColorPickerOpen(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') setForegroundColorPickerOpen(false)
                    }}
                  >
                    <XClose
                      sx={{ padding: '2px', position: 'absolute', top: '5px', right: '5px' }}
                      onClick={() => setForegroundColorPickerOpen(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === 'Escape') setForegroundColorPickerOpen(false)
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={3} alignItems="center">
              <Sun width={30} height={30} />
              <Slider
                color="neutral"
                size="lg"
                value={foregroundLuminosity}
                min={0.0}
                max={100.0}
                step={0.1}
                onChange={(e) => {
                  const luminosity = parseFloat(e.target.value)
                  setForegroundLuminosity(luminosity)
                  const hslVal = hexToHSL(backgroundColor)
                  const newHsl = `hsl(${hslVal.split(',')[0].split('(')[1]}, ${hslVal.split(',')[1]}, ${luminosity}%)`
                  const hexValue = hslToHex(newHsl)
                  setForegroundColor(hexValue.toUpperCase())
                }}
              />
            </Stack>
          </FormControl>
          <Button
            color="neutral"
            variant="plain"
            startDecorator={<SwitchHorizontal01 />}
            onClick={() => {
              setForegroundColor(backgroundColor)
              setBackgroundColor(foregroundColor)
            }}
          >
            Swap color
          </Button>
          <FormControl sx={{ flex: 3, width: '100%' }}>
            <FormLabel>Background Color</FormLabel>
            <Box sx={{ position: 'relative' }}>
              <Input
                placeholder="#FFFFFF"
                size="lg"
                sx={{ mb: 2 }}
                onChange={(e) => {
                  let value = e.target.value

                  if (!value.startsWith('#')) {
                    value = `#${value.replace(/#/g, '')}`
                  }

                  if (value.length <= 7 && checkValidHexInput(value)) {
                    setBackgroundColor(value.toUpperCase())
                  }
                }}
                value={backgroundColor}
                endDecorator={
                  <Box
                    onClick={() => setBackgroundColorPickerOpen(!backgroundColorPickerOpen)}
                    sx={{
                      borderRadius: '50%',
                      backgroundColor,
                      width: '24px',
                      height: '24px',
                      border: `1px solid ${colors.neutral[300]}`,
                      cursor: 'pointer',
                    }}
                  />
                }
              />
              {Boolean(backgroundColorPickerOpen) && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '65px',
                    right: '10px',
                    zIndex: '9999',
                    '.react-colorful': {
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                      borderRadius: '8px',
                      background: 'white',
                      padding: 3,
                    },
                    '::before': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-10px',
                      right: '10px',
                      width: '0',
                      height: '0',
                      borderLeft: '10px solid transparent',
                      borderRight: '10px solid transparent',
                      borderTop: '10px solid white',
                      zIndex: '9998',
                    },
                  }}
                >
                  <HexColorPicker
                    color={backgroundColor}
                    onChange={(color) => setBackgroundColor(color.toUpperCase())}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') setBackgroundColorPickerOpen(false)
                    }}
                  />
                  <Box
                    sx={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '5px' }}
                    onClick={() => setBackgroundColorPickerOpen(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Escape') setBackgroundColorPickerOpen(false)
                    }}
                  >
                    <XClose />
                  </Box>
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={3} alignItems="center">
              <Sun width={30} height={30} />
              <Slider
                color="neutral"
                size="lg"
                value={backgroundLuminosity}
                min={0.0}
                max={100.0}
                step={0.1}
                onChange={(e) => {
                  const luminosity = parseFloat(e.target.value)
                  setBackgroundLuminosity(luminosity)
                  const hslVal = hexToHSL(backgroundColor)
                  const newHsl = `hsl(${hslVal.split(',')[0].split('(')[1]}, ${hslVal.split(',')[1]}, ${luminosity}%)`
                  const hexValue = hslToHex(newHsl)
                  setBackgroundColor(hexValue.toUpperCase())
                }}
              />
            </Stack>
          </FormControl>
        </Stack>
      </Form>
      <Grid
        container
        spacing={5.5}
        sx={{
          marginLeft: `-${5.5 * 4}px !important`,
          marginRight: `-${5.5 * 4}px !important`,
        }}
      >
        <Grid xs={12} lg={6}>
          <Card color="primary" size="lg" sx={{ height: '100%', borderRadius: '8px' }}>
            <Stack spacing={5}>
              <Stack spacing={5}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      level="title-md"
                      endDecorator={
                        <Tooltip
                          title="Contrast ratio refers to how bright or dark colors appear on screens. The more scientific definition is that contrast is a ratio of the luminance of the brightest color to the darkest color that the system can produce. Contrast ratios range from 1 to 21 (written as 1:1 and 21:1). The first number, L1, refers to the relative luminance of light colors while L2 is the second number that refers to the relative luminance of dark colors."
                          placement="top"
                        >
                          {/* Needs span to carry a ref - https://stackoverflow.com/questions/57527896/material-ui-tooltip-doesnt-display-on-custom-component-despite-spreading-props */}
                          <span>
                            <InfoCircle stroke="red" />
                          </span>
                        </Tooltip>
                      }
                    >
                      Your Contrast Ratio
                    </Typography>
                  </Stack>
                  <Button variant="plain" size="sm" endDecorator={<Copy04 />}>
                    <Box component="span" sx={{ display: { xs: 'none', sm: 'inline-block' } }} onClick={handleShare}>
                      {isCopied ? 'Copied!' : 'Share results'}
                    </Box>
                    <Box component="span" sx={{ display: { sm: 'none' } }}>
                      {isCopied ? 'Copied!' : 'Share'}
                    </Box>
                  </Button>
                  <Box
                    sx={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: '1px',
                      margin: '-1px',
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      width: '1px',
                    }}
                    aria-live="polite"
                  >
                    <p>{`Your ratio is ${ratio}:1. ${
                      pass === false
                        ? `Your colors do not meet WCAG requirements. ${level}.`
                        : `Your colors meet WCAG requirements. ${level}. `
                    }`}</p>
                    <p>{`Small text compliance level AA ${smallTextAAPassed >= 4.5 ? 'pass' : 'fail'}. Small text compliance level AAA ${
                      smallTextAAAPassed >= 7 ? 'pass' : 'fail'
                    }.`}</p>
                    <p>{`Large text compliance level AA ${largeTextAAPassed >= 3 ? 'pass' : 'fail'}. Large text compliance level AAA ${
                      largeTextAAAPassed >= 4.5 ? 'pass' : 'fail'
                    }.`}</p>
                  </Box>
                </Stack>
                <Stack spacing={2}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                  >
                    <Typography displayLarge>{ratio}:1</Typography>
                    {complianceLevel.chip}
                  </Stack>
                  {complianceLevel.message}
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={5}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={1}>
                    <Typography level="title-md">Your Compliance Levels</Typography>
                  </Stack>
                  <Typography>
                    Your compliance level is measured against WCAG guidelines, level AAA being the highest standard.
                  </Typography>
                </Stack>
                <Stack spacing={2.75}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Box sx={{ flex: 2 }}>
                      <Typography level="title-md">Large Text</Typography>
                      <Typography level="body-sm">
                        Larger than 18pt regular or 14pt bold, minimum contrast 3:1
                      </Typography>
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ flex: 3 }}>
                      <Chip
                        startDecorator={largeTextAAPassed ? <Check /> : <XClose />}
                        color={largeTextAAPassed ? 'primary' : 'neutral'}
                        variant={largeTextAAPassed ? 'soft' : 'outlined'}
                      >
                        Level AA
                      </Chip>
                      <Chip
                        startDecorator={largeTextAAAPassed ? <Check /> : <XClose />}
                        color={largeTextAAAPassed ? 'primary' : 'neutral'}
                        variant={largeTextAAAPassed ? 'soft' : 'outlined'}
                      >
                        Level AAA
                      </Chip>
                    </Stack>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Box sx={{ flex: 2 }}>
                      <Typography level="title-md">Small Text</Typography>
                      <Typography level="body-sm">Smaller than 14pt regular, minimum contrast 4.5:1</Typography>
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ flex: 3 }}>
                      <Chip
                        startDecorator={smallTextAAPassed ? <Check /> : <XClose />}
                        color={smallTextAAPassed ? 'primary' : 'neutral'}
                        variant={smallTextAAPassed ? 'soft' : 'outlined'}
                      >
                        Level AA
                      </Chip>
                      <Chip
                        startDecorator={smallTextAAAPassed ? <Check /> : <XClose />}
                        color={smallTextAAAPassed ? 'primary' : 'neutral'}
                        variant={smallTextAAAPassed ? 'soft' : 'outlined'}
                      >
                        Level AAA
                      </Chip>
                    </Stack>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Box sx={{ flex: 2 }}>
                      <Typography level="title-md">Graphics & UI elements</Typography>
                      <Typography level="body-sm">Icons, buttons, minimum contrast 3:1</Typography>
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ flex: 3 }}>
                      <Chip
                        startDecorator={graphicsPassed ? <Check /> : <XClose />}
                        color={graphicsPassed ? 'primary' : 'neutral'}
                        variant={graphicsPassed ? 'soft' : 'outlined'}
                      >
                        Level AA
                      </Chip>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid xs={12} lg={6}>
          <Stack spacing={3} sx={{ height: '100%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
            >
              <Stack direction="row" spacing={1}>
                <Typography level="title-md">Color Preview</Typography>
              </Stack>
              <Switch
                size="lg"
                color={grayscale ? 'primary' : 'neutral'}
                endDecorator="Preview colors in Grayscale"
                checked={grayscale}
                value={grayscale}
                onChange={() => setGrayscale(!grayscale)}
                sx={{ alignSelf: { xs: 'flex-start', sm: 'flex-end' } }}
              />
            </Stack>
            <Card
              color="primary"
              size="lg"
              sx={{
                backgroundColor: `${backgroundColor} !important`,
                color: `${foregroundColor} !important`,
                borderRadius: '8px',
                height: '100%',
                filter: grayscale ? 'grayscale(100%)' : 'grayscale(0)',
              }}
            >
              <Stack spacing={4}>
                <Stack spacing={3}>
                  <Typography level="title-md">
                    <span style={{ color: foregroundColor }}>Large Text</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '24px', fontWeight: '500', letterSpacing: '0' }}>
                    <span style={{ color: foregroundColor }}>This is large text, 24 point, medium weight</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '18px', fontWeight: '400' }}>
                    <span style={{ color: foregroundColor }}>This is large text, 18 point, regular weight</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '14px', fontWeight: '800' }}>
                    <span style={{ color: foregroundColor }}>This is large text, 14 point, bold weight</span>
                  </Typography>
                </Stack>
                <Divider sx={{ backgroundColor: foregroundColor }} />
                <Stack spacing={3}>
                  <Typography level="title-md">
                    <span style={{ color: foregroundColor }}>Small Text</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '16px', fontWeight: '500' }}>
                    <span style={{ color: foregroundColor }}>TThis is small text, 16 point, medium weight</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '16px', fontWeight: '400' }}>
                    <span style={{ color: foregroundColor }}>This is small text, 16 point, regular weight</span>
                  </Typography>
                  <Typography component="p" sx={{ fontSize: '12px', fontWeight: '800' }}>
                    <span style={{ color: foregroundColor }}>This is small text, 12 point, bold weight</span>
                  </Typography>
                </Stack>
                <Divider sx={{ backgroundColor: foregroundColor }} />
                <Stack spacing={1}>
                  <Typography level="title-md">
                    <span style={{ color: foregroundColor }}>Graphics & UI Elements</span>
                  </Typography>
                  <Typography>
                    <span style={{ color: foregroundColor }}>The input field below is a graphic element</span>
                  </Typography>
                  <Box
                    sx={{ border: `1px solid ${foregroundColor}`, pt: 1, pb: 1, pr: 1.5, pl: 1.5, borderRadius: '4px' }}
                  >
                    <Typography>
                      <span style={{ color: foregroundColor }}>Sample input field</span>
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ColorContrastChecker
