import { storyblokEditable } from '@storyblok/react/rsc'
import JoyCheckbox from '@mui/joy/Checkbox'
import determineCheckboxColor from '@/lib/determine-checkbox-color'

const Checkbox = ({ blok, backgroundColor }) => {
  const color = determineCheckboxColor({ backgroundColor })

  return <JoyCheckbox {...storyblokEditable(blok)} color={color} size="md" variant="outlined" />
}

export default Checkbox
