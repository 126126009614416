import JoyTooltip from '@mui/joy/Tooltip'

const Tooltip = ({ children, ...rest }) => {
  return (
    <JoyTooltip arrow {...rest}>
      {children}
    </JoyTooltip>
  )
}

export default Tooltip
