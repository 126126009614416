'use client'

import React from 'react'
import NextLink from 'next/link'
import JoyIconButton from '@mui/joy/IconButton'
import trackEvent from '../../lib/track-event'

const IconButton = React.forwardRef(({ children, ...rest }, ref) => {
  const iconButtonProps = rest.href ? { component: NextLink } : {}

  return (
    <JoyIconButton
      {...rest}
      {...iconButtonProps}
      ref={ref}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Icon Button Clicked', {
          Destination: rest.href,
        })
      }}
    >
      {children}
    </JoyIconButton>
  )
})

IconButton.displayName = 'IconButton'

export default IconButton
