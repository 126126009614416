import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import Card from '@audioeye-marketing/ui/src/components/Card'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'

const ListGroup = ({
  blok,
  backgroundColor,
  collapsePadding,
  listSize,
  headingLevel = 'body-sm',
  removeListItemTargetBlankIcon,
}) => {
  const Wrapper = blok?.card ? Card : Box
  const wrapperProps = blok?.card ? { noBorderRadius: true, variant: 'plain' } : {}

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      {...wrapperProps}
      sx={{
        padding: collapsePadding ? 0 : { xs: 2, md: 3 },
        height: '100%',
        alignItems: blok?.heading ? 'flex-start' : 'flex-end',
        display: 'flex',
      }}
    >
      <Stack spacing={listSize === 'sm' ? 1.25 : 2.5} sx={{ width: '100%' }}>
        {Boolean(blok?.heading) && <Typography level={headingLevel}>{blok?.heading}</Typography>}
        {blok?.lists?.map((currentList) => (
          <StoryblokServerComponent
            key={currentList?._uid}
            blok={currentList}
            backgroundColor={backgroundColor}
            size={listSize}
            removeListItemTargetBlankIcon={removeListItemTargetBlankIcon}
          />
        ))}
      </Stack>
    </Wrapper>
  )
}

export default ListGroup
